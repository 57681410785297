import logo from './logo.svg';
import { useCallback, useRef, useEffect, useState } from "react";
import './App.css';
import * as mycrypto from "./mycrypto.js";
import AnimatedMessage from "./AnimatedMessage.js";

function Password(props) {
  const [pass, setPass] = useState("");

  const onAddPlayer = useCallback(() => {
    props.submitPassword(pass);
  }, [pass]);

  return (
    <div>
      <p>
        welcome, angela.
      </p>
      <input
        type="text"
        value={pass}
        onChange={(e) => setPass(e.target.value)}
        onKeyUp={(e) => (e.key === "Enter" ? onAddPlayer() : 0)}
        placeholder="password"
      ></input>
      <button onClick={onAddPlayer}>submit</button>
    </div>
  );
}
function ViewMessage(props) {
  const [text, setText] = useState("");

  useEffect(() => {
      mycrypto.dcrpt(props.pass).then((tt) => {
        setText(tt);
      })
  });

  return (
    <div>
      <h6>2/13/21</h6>
      <p dangerouslySetInnerHTML={{__html: text}}/>
    </div>
  )
}
function ViewAnimatedMessage(props) {
  const [text, setText] = useState("");

  useEffect(() => {
      mycrypto.dcrpt2(props.pass).then((tt) => {
        setText(tt);
      })
  });

  return (
    <div>
      <h6>2/22/21</h6>
      <AnimatedMessage text={text} onCompletion={props.onCompletion}/>
    </div>
  )
}

function ViewPic(props) {
  const [b64Im, setB64Im] = useState("");
  const [text, setText] = useState("");
  const [opacity, setOpacity] = useState(0);

  const fadeIn = useCallback(() => {
    if (opacity > 0.9) {
      setOpacity(1.0);
      return;
    }
    setOpacity(opacity + 0.02);
  }, [opacity, setOpacity]);

  useEffect(() => {
      mycrypto.dcrpt3(props.pass).then((tt) => {
        setB64Im(tt);
      })
      mycrypto.dcrpt4(props.pass).then((tt) => {
        setText(tt);
      })
  });

  const go = props.go;
  useEffect(() => {
    if (go && opacity < 1.0) {
      setTimeout(fadeIn, 50);
    }
  }, [go, fadeIn, opacity])




  return (
    <div>
      <h6>7/19/21</h6>
      <div style={{opacity: opacity}}>
      <p dangerouslySetInnerHTML={{__html: text}}/>
      <img src={`data:image/png;base64, ${b64Im}`} className="cutepic"/>
      </div>
    </div>
  )
}

function App() {

  const [pass, setPass] = useState("");
  const [go, setGo] = useState(false);

  return (
    <div className="App">
      {pass === "" && <Password submitPassword={setPass}/>}
      {pass !== "" && <ViewMessage pass={pass}/>}
      {pass !== "" && <hr style={{margin: "2em"}}/>}
      {pass !== "" && <ViewAnimatedMessage pass={pass} onCompletion={() => setGo(true)}/>}
      {pass !== "" && <hr style={{margin: "2em"}}/>}
      {pass !== "" && <ViewPic pass={pass} go={go}/>}
    </div>
  );
}

export default App;
