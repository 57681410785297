import { useCallback, useEffect, useState } from "react"
import './AnimatedMessage.css';

function validHtml(s) {
    return (s.match(/</g)||[]).length === (s.match(/>/g)||[]).length
}

function isLetter(str) {
    return str.length === 1 && str.match(/[a-z:)-]/i);
  }

export default function AnimatedMessage(props) {
    const [displayedText, setDisplayedText] = useState("");
    const [i, setI] = useState(0);
    const [catI, setCatI] = useState(0);
    const [baseCat, setBaseCat] = useState(1);

    const endCat = useCallback(() => {
        setBaseCat(3);
    }, []);

    const advanceText = useCallback(() => {
        if (i >= props.text.length) {
            if (props.text.length > 0) {
                setCatI(0);
                setTimeout(endCat, 1500);
                props.onCompletion();
            }
            return;
        }
        setI(i+1);
        let didSwitchCat = false;
        if (validHtml(props.text.substring(0,i+1))) {
            if (isLetter(props.text.charAt(i))) {
                setCatI(catI + 1);
                didSwitchCat = true;
            }
            setDisplayedText(props.text.substring(0,i+1))
        }
        if (!didSwitchCat && catI % 2 !== 0) {
            setCatI(0);
        }
    }, [props, i]);

    useEffect(() => {
        setTimeout(advanceText, 1500);
    }, [props.text])

    useEffect(() => {
        setTimeout(advanceText, 70);
    }, [i])

  return (
    <div>
      <p dangerouslySetInnerHTML={{__html: displayedText}}/><img className="arvidcat" src={`arvid${baseCat+catI%2}.png`}/>
    </div>
  )
}